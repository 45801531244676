import { getUser } from '@/api/AuthRepository'
import { rolesIds } from '@/roles'

const state = {
  userLogged: null,
}

const mutations = {
  updateUser (state, user) {
    state.userLogged = user
  },
  mutateUserLogged (state, value) {
    state.userLogged = value
  },
}

const actions = {
  fetchUser ({ commit }) {
    return new Promise((resolve) => {
      getUser()
        .then(user => {
          commit('updateUser', user)
          resolve()
        })
        .catch(() => {
          resolve()
        })
    })
  },
  setUserLogged ({ commit }, value) {
    commit('mutateUserLogged', value)
  },
}

const getters = {
  getUserLogged: (state) => {
    return state.userLogged
  },
  getUserRole: (state) => {
    return state.userLogged.user_role
  },
  isAdmin () {
    return state.userLogged.user_role.id === rolesIds.admin
  },
  isStockManager () {
    return state.userLogged.user_role.id === rolesIds.admin
  },

}

export default {
  state,
  mutations,
  actions,
  getters,
}
