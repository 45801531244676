// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './sass/style.scss'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import { rolesIds } from '@/roles'
import LanguageRepository from '@/api/LanguageRepository'
import { getCookie } from '@/helpers/helpers'

Vue.config.productionTip = false

Vue.filter('humanizeString', function (input) {
  if (!input) {
    return ''
  }
  input = input.toLowerCase().replace(/[_-]+/g, ' ').replace(/\s{2,}/g, ' ').trim()
  input = input.charAt(0).toUpperCase() + input.slice(1)
  return input
})

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

window.Vue = new Vue({
  data () {
    return {
      rolesIds,
    }
  },
  async created () {
    LanguageRepository.language()
      .then(({ data }) => {
        const general = data.general
        delete data.general
        data = {
          ...data,
          ...general,
        }
        const lang = getCookie('lang')
        this.$i18n.setLocaleMessage(lang, data)
        this.$i18n.locale = lang
      })
    await this.$store.dispatch('fetchUser')
  },

  store,
  vuetify,
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
