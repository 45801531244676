import decode from 'jwt-decode'
import Repository from './Repository'

import EventBus from '../plugins/EventBus'
import axios from 'axios'

const AUTH_TOKEN_KEY = 'authToken'

export function loginUser (email, password) {
    return new Promise((resolve, reject) => {
        Repository.post('/api/auth/login', {
            email,
            password,
        })
            .then(res => {
                setAuthToken(res.data.access_token)
                resolve()
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function refreshToken () {
    Repository._refreshToken = true
    return new Promise((resolve, reject) => {
        Repository.post('/api/auth/refresh')
            .then(res => {
                const token = res.headers.authorization.replace('Bearer ', '')
                setAuthToken(token)
                resolve(token)
            })
            .catch((err) => {
                clearAuthToken()
                reject(err)
            })
    })
}

export function getUser () {
  // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        try {
            const userRes = await Repository.post('/api/auth/me')
            const user = userRes.data
            resolve(user)
        } catch (err) {
            reject(err)
        }
    })
}

export function logout () {
    return new Promise((resolve) => {
        logoutUser()
        EventBus.$emit('logoutUser')
        resolve()
    })
}

export function logoutUser () {
    clearAuthToken()
}

export function setAuthToken (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    localStorage.setItem(AUTH_TOKEN_KEY, token)
}

export function getAuthToken () {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    if (token === 'undefined' || token === 'null') {
        return null
    }
    return token
}

export function clearAuthToken () {
    Repository.defaults.headers.common.Authorization = ''
    localStorage.removeItem(AUTH_TOKEN_KEY)
}

export function isLoggedIn () {
    const authToken = getAuthToken()

    if (!authToken) { return false }
    // setAuthToken(authToken);
    return !!authToken
}

export function getUserInfo () {
    if (isLoggedIn()) {
        return decode(getAuthToken())
    }
}

// eslint-disable-next-line no-unused-vars
function getTokenExpirationDate (encodedToken) {
    const token = decode(encodedToken)
    if (!token.exp) {
        return null
    }

    const date = new Date(0)
    date.setUTCSeconds(token.exp)

    return date
}
