import Vue from 'vue'
const state = {
  items: [],
  client: null,
  shippingCompany: null,
}

const mutations = {
  addToCart (state, item) {
    Vue.set(item, 'amount', 1)
    state.items.push(item)
  },
  setAmount (state, { item, value }) {
    const element = state.items.find(element => {
      return element.id === item.id
    })
    element.amount = value
    if (element.amount === 0) {
      state.items = state.items.filter(element => element.id !== item.id)
    }
  },
  updateAmount (state, { item, value }) {
    const element = state.items.find(element => {
      return element.id === item.id
    })
    element.amount = element.amount + value
    if (element.amount === 0) {
      state.items = state.items.filter(element => element.id !== item.id)
    }
  },
  removeFromCart (state, item) {
    state.items = state.items.filter(obj => {
      return obj.part.id !== item.part.id
    })
  },

  clear (state) {
    state.items = []
    state.client = null
    state.shippingCompany = null
  },

  updateClient (state, client) {
    state.client = client
  },

  updateShippingCompany (state, shippingCompany) {
    state.shippingCompany = shippingCompany
  },
}

const actions = {
  addItemToCart ({ state, commit }, item) {
    commit('addToCart', item)
  },
  setItemAmount ({ state, commit }, { item, value }) {
    commit('setAmount', { item, value })
  },
  incrementItemAmount ({ state, commit }, item) {
    commit('updateAmount', { item, value: 1 })
  },
  decrementItemAmount ({ state, commit }, item) {
    commit('updateAmount', { item, value: -1 })
  },
  removeItemFromCart ({ state, commit }, item) {
    commit('removeFromCart', item)
  },
  clearCart ({ state, commit }) {
    commit('clear')
  },
  updateClientInfo ({ state, commit }, client) {
    commit('updateClient', client)
  },
  updateShippingCompanyInfo ({ state, commit }, shippingCompany) {
    commit('updateShippingCompany', shippingCompany)
  },
}

const getters = {
  getItemOnCart: (state) => (item) => {
    return state.items.find(element => element.id === item.id)
  },
  getItems: (state) => {
    return state.items
  },
  getTotalItemsOnCart: (state) => {
    return state.items.reduce((acc, element) => acc + element.amount, 0)
  },
}
export default {
  state,
  mutations,
  actions,
  getters,
}
