<template>
  <div>
    <v-row class="detailsPanelRow">
      <v-col class="detailsPanelLeft">
        <span v-text="title" />
      </v-col>
      <v-col class="detailsPanelRight">
        <div v-if="hasRightPanel">
          <span
            v-if="content"
            v-text="content"
          />
          <span v-else />
        </div>
        <slot />
      </v-col>
    </v-row>
    <v-divider
      v-if="divider"
      class="m-0"
    />
  </div>
</template>

<script>
export default {
  name: 'DetailsPanelItem',
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    divider: {
      type: Boolean,
      default: false,
    },
    hasRightPanel: {
      type: Boolean,
      default: true,
    },

  },
}
</script>

<style scoped>

.detailsPanelLeft {
  word-break: break-all;
  font-weight: bold;
}

.detailsPanelRight {
  word-break: break-all;

}
</style>
