import Repository from './Repository'

const resource = '/api/language'

export default {
    change (lang) {
        return Repository.put(`${resource}/${lang}`)
    },
    language () {
        return Repository.get(`${resource}`)
    },
}
