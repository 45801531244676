export const capitalize = (value) => {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const getCookie = (name) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)

  if (parts.length === 2) return parts.pop().split(';').shift()
}

export const parseErrors = (response) => {
  const { data } = response
  const errors = {}
  Object.keys(data.errors).forEach(key => {
    errors[key] = data.errors[key]
  })

  return errors
}
