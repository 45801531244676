import Vue from 'vue'
import VueRouter from 'vue-router'
import { isLoggedIn } from '@/api/AuthRepository'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: () => import('@/views/auth/Index'),
    children: [
      {
        path: '',
        component: () => import('@/views/auth/pages/Login'),
        name: 'login',
        meta: {
          allowAnonymous: true,
        },
      },
    ],
  },
  {
    path: '/',
    redirect: '/orders',
    component: () => import('@/views/dashboard/Index'),
    children: [
      // Dashboard
      {
        name: 'Dashboard',
        path: 'dashboard',
        component: () => import('@/views/dashboard/Dashboard'),
      },
      // Pages
      {
        name: 'Cart',
        path: 'cart',
        component: () => import('@/views/dashboard/pages/Cart/Cart'),
      },
      {
        name: 'Entities',
        path: 'entities',
        component: () => import('@/views/dashboard/pages/EntitiesIndex/EntitiesList'),
      },
      {
        path: '/entities/:entityId',
        component: () => import('@/views/dashboard/pages/EntityShow'),
        redirect: '/entities/:entityId/orders',
        props (route) {
          const props = { ...route.params }
          props.entityId = parseInt(props.entityId)
          return props
        },
        children: [
          {
            path: '/entities/:entityId/orders',
            component: () => import('@/views/dashboard/pages/OrdersIndex/components/EntityOrdersListWrapper'),
            name: 'Entity Orders',
            props (route) {
              const props = { ...route.params }
              props.entityId = parseInt(props.entityId)
              return props
            },
          },
          {
            path: '/entities/:entityId/clients',
            component: () => import('@/views/dashboard/pages/EntityClientsList/EntityClientsList'),
            name: 'Entity Clients',
            props (route) {
              const props = { ...route.params }
              props.entityId = parseInt(props.entityId)
              return props
            },
          },
          {
            path: '/entities/:entityId/parts',
            component: () => import('@/views/dashboard/pages/PartsIndex/components/EntityPartsListWrapper'),
            name: 'Entity Parts',
            props (route) {
              const props = { ...route.params }
              props.entityId = parseInt(props.entityId)
              return props
            },
          },
          {
            path: '/entities/:entityId/collaborators',
            component: () => import('@/views/dashboard/pages/EntityCollaboratorsList/EntityCollaboratorsList'),
            name: 'Entity Collaborators',
            props (route) {
              const props = { ...route.params }
              props.entityId = parseInt(props.entityId)
              return props
            },
          },
        ],
      },
      {
        name: 'Orders',
        path: 'orders',
        component: () => import('@/views/dashboard/pages/OrdersIndex/OrdersIndex'),
      },
      {
        name: 'Order Show',
        path: 'orders/:orderId',
        component: () => import('@/views/dashboard/pages/OrderShow/OrderShow'),
        props (route) {
          const props = { ...route.params }
          props.orderId = parseInt(props.orderId)
          return props
        },
      },
      {
        name: 'Collaborators',
        path: 'collaborators',
        component: () => import('@/views/dashboard/pages/CollaboratorsIndex'),
      },
      {
        name: 'Clients',
        path: 'clients',
        component: () => import('@/views/dashboard/pages/ClientsIndex'),
      },
      {
        name: 'Shipping Companies',
        path: 'shipping-companies',
        component: () => import('@/views/dashboard/pages/ShippingCompaniesIndex'),
      },
      {
        name: 'Parts',
        path: 'parts',
        component: () => import('@/views/dashboard/pages/PartsIndex/PartsIndex'),
      },
      {
        name: 'Create Parts',
        path: 'parts/create',
        component: () => import('@/views/dashboard/pages/PartCreate'),
      },
      {
        name: 'Parts Show',
        path: 'parts/:partId',
        component: () => import('@/views/dashboard/pages/ShowPart/PartShow'),
        props: true,
      },

      {
        name: 'Users',
        path: 'users',
        component: () => import('@/views/dashboard/pages/UsersIndex/UsersIndex'),
        props: true,
      },
    ],
  },
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.name === 'login' && isLoggedIn()) {
    next({ path: '/' })
  } else if (!to.meta.allowAnonymous && !isLoggedIn()) {
    next({
      path: '/login',
    })
  } else {
    next()
  }
})

export default router
